import React from 'react';
import { DotButton, useDotButton } from './CarouselDotButton';
import {
    PrevButton,
    NextButton,
    usePrevNextButtons,
} from './CarouselArrowButtons';
import useEmblaCarousel from 'embla-carousel-react';

import './carousel.css';
import './base.css';

const ImageCarousel = (props) => {
    const { slides, options } = props;
    const [emblaRef, emblaApi] = useEmblaCarousel(options);

    const { selectedIndex, scrollSnaps, onDotButtonClick } =
        useDotButton(emblaApi);

    const {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
    } = usePrevNextButtons(emblaApi);

    return (
        <section className="embla">
            <div className="embla__viewport" ref={emblaRef}>
                <div className="embla__container">
                    {slides.map((slide, index) => (
                        <div
                            className="embla__slide"
                            key={index}
                            style={{
                                background: '#FFEEEE',
                                padding: '10px 10px 10px 20px',
                                borderRadius: '6px',
                                // boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                            }}
                        >
                            <div
                                style={{
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    background: 'white',
                                    padding: '20px',
                                    borderRadius: '6px',
                                    boxShadow: '0 3px 10px rgb(0 0 0 / 0.2)',
                                }}
                            >
                                <img
                                    src={slide.image}
                                    style={{
                                        width: '100%',
                                        borderRadius: '6px',
                                        flexGrow: 1,
                                        border: '1px solid lightgrey',
                                    }}
                                    alt={slide.altText}
                                />
                                <div style={{ margin: '1.8rem 0 0 0' }}>
                                    {slide.description}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div
                style={
                    {
                        // borderBottom: '1px solid lightgrey',
                        // margin: '1.8rem -20px 0 -20px',
                    }
                }
            ></div>

            <div className="embla__controls">
                <div className="embla__buttons">
                    <PrevButton
                        onClick={onPrevButtonClick}
                        disabled={prevBtnDisabled}
                    />
                    <NextButton
                        onClick={onNextButtonClick}
                        disabled={nextBtnDisabled}
                    />
                </div>

                <div className="embla__dots">
                    {scrollSnaps.map((_, index) => (
                        <DotButton
                            key={index}
                            onClick={() => onDotButtonClick(index)}
                            className={'embla__dot'.concat(
                                index === selectedIndex
                                    ? ' embla__dot--selected'
                                    : ''
                            )}
                        />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default ImageCarousel;
