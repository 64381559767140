import React, { useRef, useEffect, useState } from 'react';
import { useGLTF, useAnimations } from '@react-three/drei';

// get fbx file from mixamo with animations and model
// import into blender and export as glb (dont do anything)
// upload to https://optimizeglb.com/ to compress (60MB to 1MB!!!)
// make react component here from glb file: https://gltf.pmnd.rs/
export default function Scene(props) {
    const group = useRef();
    const { nodes, materials, animations } = useGLTF('/scene.glb');
    const { actions } = useAnimations(animations, group);

    useEffect(() => {
        actions['Armature|mixamo.com|Layer0'].play();
    }, []);

    const [prevScrollPos, setPrevScrollPos] = useState(0);
    const rotationIncrement = 0.002; // Adjust this value as needed

    useEffect(() => {
        const contentContainer = document.getElementById('content-container');

        const handleScroll = () => {
            const currentScrollPos = contentContainer.scrollTop;

            const scrollDelta = currentScrollPos - prevScrollPos;
            const rotationDelta = scrollDelta * rotationIncrement;

            group.current.rotation.y += rotationDelta;

            setPrevScrollPos(currentScrollPos);
        };

        contentContainer.addEventListener('scroll', handleScroll);

        return () => {
            contentContainer.removeEventListener('scroll', handleScroll);
        };
    }, [group, prevScrollPos, rotationIncrement]);

    return (
        <group ref={group} {...props} dispose={null}>
            <group name="Scene">
                <group name="Armature">
                    <skinnedMesh
                        name="EyeLeft"
                        geometry={nodes.EyeLeft.geometry}
                        material={materials.Wolf3D_Eye}
                        skeleton={nodes.EyeLeft.skeleton}
                        morphTargetDictionary={
                            nodes.EyeLeft.morphTargetDictionary
                        }
                        morphTargetInfluences={
                            nodes.EyeLeft.morphTargetInfluences
                        }
                    />
                    <skinnedMesh
                        name="EyeRight"
                        geometry={nodes.EyeRight.geometry}
                        material={materials.Wolf3D_Eye}
                        skeleton={nodes.EyeRight.skeleton}
                        morphTargetDictionary={
                            nodes.EyeRight.morphTargetDictionary
                        }
                        morphTargetInfluences={
                            nodes.EyeRight.morphTargetInfluences
                        }
                    />
                    <skinnedMesh
                        name="Wolf3D_Body"
                        geometry={nodes.Wolf3D_Body.geometry}
                        material={materials.Wolf3D_Body}
                        skeleton={nodes.Wolf3D_Body.skeleton}
                    />
                    <skinnedMesh
                        name="Wolf3D_Glasses"
                        geometry={nodes.Wolf3D_Glasses.geometry}
                        material={materials.Wolf3D_Glasses}
                        skeleton={nodes.Wolf3D_Glasses.skeleton}
                    />
                    <skinnedMesh
                        name="Wolf3D_Hair"
                        geometry={nodes.Wolf3D_Hair.geometry}
                        material={materials.Wolf3D_Hair}
                        skeleton={nodes.Wolf3D_Hair.skeleton}
                    />
                    <skinnedMesh
                        name="Wolf3D_Head"
                        geometry={nodes.Wolf3D_Head.geometry}
                        material={materials.Wolf3D_Skin}
                        skeleton={nodes.Wolf3D_Head.skeleton}
                        morphTargetDictionary={
                            nodes.Wolf3D_Head.morphTargetDictionary
                        }
                        morphTargetInfluences={
                            nodes.Wolf3D_Head.morphTargetInfluences
                        }
                    />
                    <skinnedMesh
                        name="Wolf3D_Outfit_Bottom"
                        geometry={nodes.Wolf3D_Outfit_Bottom.geometry}
                        material={materials.Wolf3D_Outfit_Bottom}
                        skeleton={nodes.Wolf3D_Outfit_Bottom.skeleton}
                    />
                    <skinnedMesh
                        name="Wolf3D_Outfit_Footwear"
                        geometry={nodes.Wolf3D_Outfit_Footwear.geometry}
                        material={materials.Wolf3D_Outfit_Footwear}
                        skeleton={nodes.Wolf3D_Outfit_Footwear.skeleton}
                    />
                    <skinnedMesh
                        name="Wolf3D_Outfit_Top"
                        geometry={nodes.Wolf3D_Outfit_Top.geometry}
                        material={materials.Wolf3D_Outfit_Top}
                        skeleton={nodes.Wolf3D_Outfit_Top.skeleton}
                    />
                    <skinnedMesh
                        name="Wolf3D_Teeth"
                        geometry={nodes.Wolf3D_Teeth.geometry}
                        material={materials.Wolf3D_Teeth}
                        skeleton={nodes.Wolf3D_Teeth.skeleton}
                        morphTargetDictionary={
                            nodes.Wolf3D_Teeth.morphTargetDictionary
                        }
                        morphTargetInfluences={
                            nodes.Wolf3D_Teeth.morphTargetInfluences
                        }
                    />
                    <primitive object={nodes.Hips} />
                </group>
                <mesh
                    name="koltuk"
                    castShadow
                    receiveShadow
                    geometry={nodes.koltuk.geometry}
                    material={materials.chair}
                    rotation={[0, -1.551, 0]}
                />
                <group
                    name="masa"
                    position={[0.581, 0.029, -0.409]}
                    rotation={[Math.PI / 2, 0, -3.13]}
                    scale={0.253}
                >
                    <mesh
                        name="masa_Cylinder"
                        castShadow
                        receiveShadow
                        geometry={nodes.masa_Cylinder.geometry}
                        material={materials.PaletteMaterial001}
                    />
                    <mesh
                        name="masa_Cylinder_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.masa_Cylinder_1.geometry}
                        material={materials.masa}
                    />
                </group>
                <group
                    name="monitör"
                    position={[0.581, 0.029, -0.409]}
                    rotation={[Math.PI / 2, 0, -3.13]}
                    scale={0.253}
                >
                    <mesh
                        name="monitör_Cube001"
                        castShadow
                        receiveShadow
                        geometry={nodes.monitör_Cube001.geometry}
                        material={materials.PaletteMaterial003}
                    />
                    <mesh
                        name="monitör_Cube001_1"
                        castShadow
                        receiveShadow
                        geometry={nodes.monitör_Cube001_1.geometry}
                        material={materials.PaletteMaterial002}
                    />
                </group>
                <mesh
                    name="kitap"
                    castShadow
                    receiveShadow
                    geometry={nodes.kitap.geometry}
                    material={materials.PaletteMaterial004}
                    position={[0.581, 0.029, -0.409]}
                    rotation={[Math.PI / 2, 0, -3.13]}
                    scale={0.253}
                />
                <mesh
                    name="mouse"
                    castShadow
                    receiveShadow
                    geometry={nodes.mouse.geometry}
                    material={materials.PaletteMaterial005}
                    position={[-0.245, 0.754, 0.679]}
                    rotation={[Math.PI / 2, 0, -3.13]}
                    scale={0.22}
                />
                <mesh
                    name="klavye"
                    castShadow
                    receiveShadow
                    geometry={nodes.klavye.geometry}
                    material={materials.PaletteMaterial006}
                    position={[0.122, 0.751, 0.697]}
                    rotation={[2.611, -0.027, 3.126]}
                    scale={0.024}
                />
                <mesh
                    name="mousepad"
                    castShadow
                    receiveShadow
                    geometry={nodes.mousepad.geometry}
                    material={materials.mousepad}
                    position={[-0.241, 0.73, 0.679]}
                    rotation={[-Math.PI, 0.011, -Math.PI]}
                    scale={[0.13, 0.093, 0.098]}
                />
                <mesh
                    name="Cylinder002"
                    castShadow
                    receiveShadow
                    geometry={nodes.Cylinder002.geometry}
                    material={materials['Material #2147479957']}
                    scale={0.01}
                />
                <mesh
                    name="Old_Dusty_Bookshelf"
                    castShadow
                    receiveShadow
                    geometry={nodes.Old_Dusty_Bookshelf.geometry}
                    material={materials.Old_Dusty_Bookshelf_Mat}
                    position={[-0.818, 0.02, -0.84]}
                    rotation={[Math.PI, -0.776, Math.PI]}
                    scale={1.118}
                />
            </group>
        </group>
    );
}

useGLTF.preload('/scene.glb');
