import React, { useState, useEffect } from 'react';
import { Canvas } from '@react-three/fiber';
import { useProgress } from '@react-three/drei';
import { Scene, Resume } from '../index';
import HeaderImg from './portfolio-header.png';
import './Main.scss';

const colors = [
    0xff0000, // Bright Red
    0xff7f00, // Bright Orange
    0xffff00, // Bright Yellow
    0x00ff00, // Bright Green
    0x00ffff, // Bright Cyan
    0x0000ff, // Bright Blue
];

export default function Main() {
    const { progress } = useProgress();
    const [colorIndex, setColorIndex] = useState(0);

    const getRandomColorIndex = () => Math.floor(Math.random() * colors.length);

    const setColor = () => {
        const randomColorIndex = getRandomColorIndex();
        setColorIndex(randomColorIndex);
    };

    useEffect(() => {
        const intervalId = setInterval(setColor, Math.random() * 4000 + 1000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="app-container">
            <div className="canvas-container">
                {/* header  */}
                <div className="header-img-container">
                    <img src={HeaderImg} className="img" />
                </div>

                {/* Loader  */}
                {progress < 100 && (
                    <div className="loader">
                        {/* Loader component */}
                        <div>Loading...</div>
                    </div>
                )}

                {/* Scene  */}
                <Canvas>
                    <ambientLight intensity={1.8} />
                    <pointLight
                        position={[0, -0.2, 0.5]}
                        intensity={2}
                        color={colors[colorIndex]}
                    />
                    <Scene
                        position={[0, -2.2, -1]}
                        rotation={[-Math.PI, 2.6, -Math.PI]}
                        scale={[1.8, 1.8, 1.8]}
                    />
                </Canvas>
            </div>

            {/* Resume */}
            <div className="content-container" id="content-container">
                <Resume />
            </div>
        </div>
    );
}
