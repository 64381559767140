import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ImageCarousel } from '..';

// wca work
import Woundle from './images/wca/woundle.png';
// seismic work
import SeismicTimeStamp from './images/seismic/timestamp.png';
// verse work
import VerseConsole from './images/verse/concierge-console/console_full.png';
import WidgetList from './images/verse/capture/widget_list.png';
import WidgetForm from './images/verse/capture/form.png';
import WidgetProd from './images/verse/capture/cardone.png';

import './WorkDemo.scss';

const WorkDemo = () => {
    const OPTIONS = { loop: true };
    // const SLIDE_COUNT = 5;
    // const SLIDES = Array.from(Array(SLIDE_COUNT).keys());
    const SLIDES = [
        {
            image: Woundle,
            description:
                'WCA - Woundle. A Wordle spin-off game made specifically for wound care with dynamic word length, hints and more.',
            altText: 'WCA - Woundle',
        },
        {
            image: SeismicTimeStamp,
            description:
                "Seismic - Timestamp Component. A reusable Timestamp component using React within Seismic's internal UI toolkit (Mantle) to display formatted dates based on string or Date objects.",
            altText: 'Seismic - Timestamp Component',
        },
        {
            image: VerseConsole,
            description:
                'Verse.io - Concierge Console. The internal tool used by the Verse concierge to enagage with leads (phone calls, sms, email).',
            altText: 'Verse.io - Concierge Console',
        },
        {
            image: WidgetList,
            description:
                "Verse.io - Capture Widget List. The customer-facing portal where users can manage their Capture Widgets. Capture Widgets allow customers to paste a small javascript snippet to display a form to capture leads. When a lead submits their information a lead is created in Verse's system where a concierge will engage.",
            altText: 'Verse.io - Capture Widget List',
        },
        {
            image: WidgetForm,
            description:
                'Verse.io - Capture Widget Form. One of the steps in the Capture Widget creation and edit flows where a user can customize the widget title, inputs, disclaimer and more.',
            altText: 'Verse.io - Capture Widget Form',
        },
        {
            image: WidgetProd,
            description:
                "Verse.io - Capture Widget On Customer Site. A Capture Widget displayed in production on a customer's website.",
            altText: 'Verse.io - Capture Widget On Customer Site',
        },
    ];

    return (
        <div className="work-container">
            <div className="back-btn">
                <Link to="/">
                    <FontAwesomeIcon icon={faArrowLeft} /> Back
                </Link>
            </div>
            <div className="work-title">Work Examples</div>
            <div className="work-content">
                <ImageCarousel slides={SLIDES} options={OPTIONS} />
            </div>
        </div>
    );
};

export default WorkDemo;
