import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Main, WorkDemo } from './components';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<Main />} />
                <Route exact path="/work-examples" element={<WorkDemo />} />
                <Route path="*" element={<Main />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
